import { render, staticRenderFns } from "./PaymentConfirmation.vue?vue&type=template&id=18465850&scoped=true&"
import script from "./PaymentConfirmation.vue?vue&type=script&lang=ts&"
export * from "./PaymentConfirmation.vue?vue&type=script&lang=ts&"
import style0 from "./PaymentConfirmation.vue?vue&type=style&index=0&id=18465850&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "18465850",
  null
  
)

/* custom blocks */
import block0 from "./PaymentConfirmation.vue?vue&type=custom&index=0&blockType=i18n"
if (typeof block0 === 'function') block0(component)

export default component.exports

/* vuetify-loader */
import installComponents from "!../../../node_modules/vuetify-loader/lib/runtime/installComponents.js"
import { VBtn } from 'vuetify/lib/components/VBtn';
import { VImg } from 'vuetify/lib/components/VImg';
import { VRadio } from 'vuetify/lib/components/VRadioGroup';
import { VRadioGroup } from 'vuetify/lib/components/VRadioGroup';
installComponents(component, {VBtn,VImg,VRadio,VRadioGroup})
