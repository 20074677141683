<template>
  <div id="main-container">
    <confirmation-headline />
    <hr />
    <div id="body-container">
      <boleto-confirmation v-if="selectedOffer.payment_method === 'Boleto'" @copy-clicked="onCopy" @pdf-clicked="onPdf" />
      <pix-confirmation v-if="selectedOffer.payment_method === 'Pix'" @copy-clicked="onCopy" @pdf-clicked="onPdf" />
      <boleto-and-pix-confirmation v-if="selectedOffer.payment_method === 'Boleto+Pix'" @copy-clicked="onCopy" @pdf-clicked="onPdf" />
    </div>
  </div>
</template>
<script>
import { defineComponent, onMounted } from "@vue/composition-api"
import { usePaymentPlansStore, useAccountStore, useCookieStore } from "@/store"
import { postUniversalMetricsEvent } from "@/api/secure"
import ConfirmationHeadline from "./ConfirmationHeadline.vue"
import BoletoConfirmation from "./BoletoConfirmation.vue"
import PixConfirmation from "./PixConfirmation.vue"
import analytics from "@/lib/analytics"
import BoletoAndPixConfirmation from "@/views/payment-plans/BoletoAndPixConfirmation.vue"

// eslint-disable-next-line @typescript-eslint/no-var-requires
const FileSaver = require("file-saver")

export default defineComponent({
  components: { BoletoAndPixConfirmation, ConfirmationHeadline, BoletoConfirmation, PixConfirmation },
  name: "Confirmation",
  setup() {
    const { selectedOffer } = usePaymentPlansStore()
    const { accountID } = useAccountStore()
    const { analyticDataLayer, updateAnalyticDataLayer } = useCookieStore()

    const onCopy = (text) => {
      navigator.clipboard.writeText(text)
    }
    const base64ToBlob = (data) => {
      // decode base64 string, remove space for IE compatibility
      const binary = window.atob(data.replace(/\s/g, ""))
      const len = binary.length
      const buffer = new ArrayBuffer(len)
      const view = new Uint8Array(buffer)
      for (let i = 0; i < len; i += 1) {
        // this line is run ~100,000 times, so counting line coverage is really slow
        /* istanbul ignore next */
        view[i] = binary.charCodeAt(i)
      }
      const blob = new Blob([view], { type: "application/pdf" })
      return blob
    }
    const downloadPDF = (base64) => {
      const blob = base64ToBlob(base64)

      FileSaver.saveAs(blob, `${selectedOffer.value.payment_method}.pdf`)
    }
    const onPdf = (pdf) => {
      downloadPDF(pdf)
    }

    onMounted(() => {
      postUniversalMetricsEvent(accountID.value, "create_payment_plan")
      analytics.track("payment_plan_created")
      analyticDataLayer.value.inDefaultNoPmtPlan = false
      updateAnalyticDataLayer()
    })

    return {
      selectedOffer,
      onCopy,
      onPdf
    }
  }
})
</script>
<style lang="scss" scoped>
  @import '~/src/scss/variables.scss';
  #main-container {
    display: block;
    margin: 0 auto;
    width: 100%;
    max-width: 600px;
  }
  #body-container {
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    margin: 1em auto;
  }
</style>
